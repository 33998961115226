import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
  data,
  redirect,
} from '@remix-run/node'
import { Form, Link, useActionData, useNavigation, useSearchParams } from '@remix-run/react'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { z } from 'zod'

import { ErrorCode, createApi, userApiRoutes } from '@rooquest/common'

import Alert from '~/components/Alert'
import ButtonV2 from '~/components/ButtonV2'
import FieldError from '~/components/FieldError'
import FieldLabel from '~/components/FieldLabel'
import PasswordInput from '~/components/PasswordInput'
import { routes } from '~/config/app-routes'
import { PageLayout } from '~/features/auth'
import {
  createUserSession,
  redirectIfUserSession,
  requireHttpMethod,
} from '~/server/session.server'
import pageTitle from '~/utils/page-title'
import { inputStyles } from '~/utils/styled-classes'
import { toast } from '~/utils/toast'

export { ErrorBoundary } from '~/components/RouteBoundaries'

export const meta: MetaFunction = () => {
  return [{ title: pageTitle('Login') }, { name: 'description', content: 'Login to Rooquest' }]
}

export let loader = async ({ request }: LoaderFunctionArgs) => {
  await redirectIfUserSession(request)
  return null
}

const formSchema = z.object({
  email: z.string().min(1, 'Email is required'),
  password: z.string().min(1, 'Password is required'),
  redirectTo: z.string().optional(),
})

export let action = async ({ request }: ActionFunctionArgs) => {
  requireHttpMethod(request, 'post')
  const formData = await request.formData()

  let fieldErrors: Record<string, string> = {}
  let formError: string | null = null

  const result = formSchema.safeParse(Object.fromEntries(formData))

  if (!result.success) {
    for (const error of result.error.errors) {
      if (error.path) fieldErrors = { ...fieldErrors, [String(error.path)]: error.message }
      if (error.code === 'custom') fieldErrors['confirmPassword'] = error.message
      formError = 'Be sure to fill out all fields'
    }

    return data({ ok: false, fieldErrors, formError }, { status: 400 })
  }

  const api = createApi(process.env.API_URL)
  const [responseErr, response] = await api(userApiRoutes, 'login', { data: result.data })

  if (responseErr) {
    if (responseErr.error.code === ErrorCode.INVALID_CREDENTIALS) {
      return data(
        { ok: false, formError: 'Invalid email or password', fieldErrors },
        { status: 401 }
      )
    }

    if (responseErr.error.code === ErrorCode.USER_NOT_VERIFIED) {
      return redirect(
        '/login?from=registration&email=' +
          encodeURIComponent(result.data.email) +
          '&error=notVerified'
      )
    }

    return data({ ok: false, formError: 'Failed to login', fieldErrors }, { status: 500 })
  }

  return createUserSession({
    session: response.data,
    redirectTo: result.data.redirectTo,
  })
}

export default function RouteLogin() {
  const navigation = useNavigation()
  const actionData = useActionData<typeof action>()

  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')
  const from = searchParams.get('from')

  // just hold the value for them so we can pre-fill the email field on
  // forgotpassword and registeration pages
  const [email, setEmail] = useState(searchParams.get('email') || '')

  const [emailSent, setEmailSent] = useState(false)
  const mutation = useMutation({
    mutationKey: ['resend-verification-email'],
    mutationFn: async () => {
      const api = createApi(window.env.API_URL)
      const [err] = await api(userApiRoutes, 'resendVerificationEmail', { data: { email } })
      if (err) throw new Error('Failed to resend verification email')
    },
    onSuccess() {
      setEmailSent(true)
      toast.success('Verification email sent!', { position: 'top-center' })
      // hide the message after 5 seconds
      setTimeout(() => setEmailSent(false), 5000)
    },
    onError() {
      toast.error('Failed to resend verification email', { position: 'top-center' })
    },
  })

  return (
    <PageLayout
      heading={
        from === 'registration' ?
          <p>
            One more step{' '}
            <FontAwesomeIcon icon={faShieldCheck} className="inline text-app-orange-base" />
          </p>
        : 'Welcome to Rooquest'
      }
      subHeading={
        from === 'registration' ?
          <p className="">
            Your account has been created! Before you can sign it you need to verify your email
            address. We've sent you an email with a link to verify your email.
            <br />
            <button
              type="button"
              className="text-gray-500 hover:underline text-sm"
              onClick={() => mutation.mutate()}
              disabled={emailSent}
            >
              {emailSent ? 'Verification email sent!' : 'Resend verification email'}
            </button>
          </p>
        : 'Lets get you signed in.'
      }
    >
      {searchParams.get('error') === 'notVerified' && (
        <Alert className="my-3">
          Your email address has not been verified. Please check your email for the verification
          link.
        </Alert>
      )}
      {actionData?.formError && <Alert className="my-3">{actionData?.formError}</Alert>}

      <Form className="space-y-3 mt-6" method="post">
        <input type="hidden" name="redirectTo" value={redirectTo || ''} />

        <div>
          <FieldLabel htmlFor="email">Email address</FieldLabel>
          <input
            autoFocus
            type="text"
            name="email"
            id="email"
            className={inputStyles.textbox}
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FieldError>{actionData?.fieldErrors?.['email']}</FieldError>
        </div>

        <div>
          <FieldLabel htmlFor="password">Password</FieldLabel>
          <PasswordInput name="password" id="password" autoComplete="current-password" />
          <FieldError>{actionData?.fieldErrors?.['password']}</FieldError>
        </div>

        <div className="pt-3">
          <ButtonV2
            type="submit"
            className="rounded-full w-full"
            aria-disabled={navigation.state === 'submitting'}
            disabled={navigation.state === 'submitting'}
          >
            {navigation.state === 'submitting' ? 'Logging in...' : 'Login'}
          </ButtonV2>
        </div>
      </Form>

      <div className="text-center sm:flex items-center justify-center mt-3 text-sm">
        <Link
          to={routes.forgotPassword(email)}
          className="text-content-dark/50 hover:text-content-dark block hover:underline"
        >
          Forgot your password?
        </Link>
        <span className="hidden sm:block">&nbsp; | &nbsp;</span>
        <p>
          <Link
            to={routes.register({ email, inviteKey: searchParams.get('inviteKey') })}
            className="text-content-dark/50 hover:text-content-dark hover:underline"
          >
            {' '}
            Create Account
          </Link>
        </p>
      </div>

      <div className="mt-3 text-xs text-center font-[400] text-[14px] leading-[22px]">
        By creating an account and using Rooquest, you accept our
        <a
          href={routes.termsOfUse}
          target="_blank"
          rel="noreferrer"
          className="text-content-dark/50 hover:text-content-dark leading-[22.4px] whitespace-nowrap hover:underline"
        >
          {' '}
          Terms of Use{' '}
        </a>
        and
        <a
          href={routes.privacyPolicy}
          target="_blank"
          rel="noreferrer"
          className="text-content-dark/50 hover:text-content-dark leading-[22.4px] whitespace-nowrap hover:underline"
        >
          {' '}
          Privacy Policy
        </a>
        .
      </div>
    </PageLayout>
  )
}
